
























































































































































































































































































































































































































































































































































































































































































































































$just_theme_color: #00563f;

.main_strategy_container {
  // display: flex;
  // gap: 4%;
  // page-break-inside: avoid;
  // @media (min-width: 320px) and (max-width: 991px) {
  //   flex-wrap: wrap;
  // }
  // @media print {
  //   flex-wrap: nowrap;
  //   margin-top: 40px !important;
  //   page-break-after: always;
  // }
  .cms_strategy_wrapper {
    // width: calc(96% / 2);
    padding: 15px;
    color: #777;
    border-radius: 10px;
    border: 1px solid #00563f;
    min-height: 215px;
    margin-block: 15px;
    position: relative;
    // @media (min-width: 320px) and (max-width: 991px) {
    //   margin-bottom: 20px;
    // }
    .icon_container {
      background-color: #00563f;
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      top: -15px;
      left: calc(50% - 15px);
    }
  }
}
.when_joel_mobile {
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
}
::v-deep .precision {
  margin: 5px;
  display: inline;
  background-color: #3c3;
  @media print {
    margin: 50px;
    display: inline;
    background-color: #3c3;
  }
}

::v-deep .technical_reasoning {
  display: inline;
  margin: 5px;
  background-color: #f93;
}

::v-deep .sequence {
  display: inline;
  margin: 5px;
  background-color: #39c;
}

::v-deep .confluence {
  display: inline;
  margin: 5px;
  background-color: #c36;
}

.label {
  border-radius: 50%;
  margin: 0.5rem;
  width: 3rem;
  height: 3rem;
  text-align: center;
  font-size: 1rem;
  line-height: 2.8;
  color: white;
}
.letter {
  font-size: 40px;
}
.for_all {
  padding: 20px 0;
  font-size: 17px;
  line-height: 1.7;
  p {
    font-size: 17px;
    line-height: 1.7;
    margin-top: 20px;
  }
}
.for_family {
  padding: 20px 0;
  font-size: 17px;
  line-height: 1.7;
  p {
    font-size: 17px;
    line-height: 1.7;
    margin-top: 20px;
  }
}
.start_with_the_lci {
  background: #00563f;
  color: #fff;
  padding: 10px 16px;
  border-radius: 5px;
}

//  Start Information Style
.card-user .card-body {
  overflow: hidden;
}
.author {
  overflow: inherit;
}
.user_bg_img {
  height: 150px;
  .new_user_background {
    background-color: $just_theme_color;
    height: 100%;
    margin: auto;
  }
}
.main_auth_user_info {
  .n_name {
    font-size: 18px;
    color: #212529;
    font-weight: 300;
    text-transform: capitalize;
  }
  .n_email {
    font-size: 17px;
    color: #9a9a9a;
    font-weight: 300;
  }
}
.which_user_type {
  width: fit-content;
  margin: auto;
  transition: 0.5s all;
  .nn {
    // background-color: $just_theme_color;
    color: #fff;
    padding: 7px 10px;
    position: relative;
    transition: 0.5s all;

    &::before {
      position: absolute;
      content: "";
      background: #91b9ae;
      width: 55px;
      height: 10px;
      bottom: 8px;
      right: -38px;
      display: none;
      // opacity: 0;
      transition: 0.5s all;
      @media (min-width: 320px) and (max-width: 1150px) {
        // z-index: 77;
        // width: 10px;
        // height: 40px;
        // bottom: 51px;
        // right: auto;
        display: none;
      }
    }
    .classification {
      position: relative;
      z-index: 6;
      padding: 0 10px;
      min-width: 180px;
      // padding: 7px 10px;
      border-radius: 12px;
      background-color: $just_theme_color;
      height: 45px;
      display: block;
      line-height: 2.3;
      font-size: 18px;
    }
  }
  .another_content {
    display: none;
    // opacity: 0;
    position: absolute;
    bottom: 8px;
    width: 358px;
    background: #91b9ae;
    border-radius: 10px;
    padding: 10px;
    right: -360px;
    transition: 0.5s all;
    z-index: 9999999999;
    @media (min-width: 1150px) and (max-width: 1280px) {
      right: -290px;
      width: 294px;
    }
    @media (min-width: 320px) and (max-width: 1150px) {
      right: auto;
      left: -60px;
      bottom: 60px;

      &::before {
        position: absolute;
        content: "";
        background: #91b9ae;
        height: 10px;
        width: 10px;
        bottom: -8px;
        left: 50%;
      }
    }
    @media (min-width: 320px) and (max-width: 567px) {
      width: 323px;
    }
    .tit {
      font-size: 15px;
    }
    .tex {
      font-size: 13px;
    }
  }
  &:hover {
    .nn {
      &::before {
        display: block;
        // opacity: 1;
      }
    }
    .another_content {
      display: block !important;
      // opacity: 1;
    }
    @media (min-width: 320px) and (max-width: 1150px) {
      .nn {
        &::before {
          display: none;
          // opacity: 0;
        }
      }
    }
  }
  @media print {
    display: block !important;
  }
}

.the_patterns_with_new_theme {
  position: relative;

  .new_theme_pattern_content {
    padding: 0 10px;
    .main_content_container {
      border-left: 2px solid;
      border-right: 2px solid;
      border-bottom: 2px solid;
      text-align: center;
      padding: 10px 5px;
      &.with_sequence {
        border-color: #39c;
      }
      &.with_precision {
        border-color: #3c3;
      }
      &.with_technical_reasoning {
        border-color: #f93;
      }
      &.with_confluence {
        border-color: #c36;
      }
      .ti {
        // color: #555;
        font-size: 15px;
      }
      .te {
        color: #777;
        font-size: 13px;
      }
    }
  }
}

.wrapper_for_use_first {
  .main_co {
    background-color: #eee;
    display: flex;
    justify-content: center;
    gap: 10px;
    color: #7d7979;
    padding: 15px;
    .a_one {
      &.with_title {
        color: #555;
      }
    }
    .a_two {
      border-left: 3px solid #777;
      border-right: 3px solid #777;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
